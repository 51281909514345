import React, { useContext, useEffect, useState } from "react";
import { SwitchWithInputContext } from "@/containers/QREditor/CreateContainer/components/SwitchWithInput/SwitchWithInput";
import { SwithProps } from "./types";

import "./styles.scss";

export const Switch = ({ label, content, name, id }: SwithProps) => {
  const Context = useContext(SwitchWithInputContext);
  const { toogleSwitch, switchVal } = Context;

  return (
    <div
      className="switch"
      data-testid="switch">
      <label
        className="switch__label"
        htmlFor="switch__toggle"
        data-testid="switch__label">
        <input
          className="switch__toggle"
          id={`switch__toggle ${id}`}
          type="checkbox"
          onClick={() => toogleSwitch()}
          checked={switchVal}
          name={name}
          defaultChecked={switchVal}
          data-val="true"
          value={"true"}
        />
        <span className="switch__span">{label}</span>
      </label>
      {content && !switchVal && <div className="swicth__content">{content}</div>}
    </div>
  );
};
