import React, { useEffect, useState, createContext, useContext } from "react";
import { useTranslation } from "react-i18next";
import { FiAlertCircle } from "react-icons/fi";
import { Controller, useFormContext } from "react-hook-form";
import { IoMdWarning } from "react-icons/io";
import { Switch } from "@/components/Switch";
import { Input } from "@/components/Input";
import { Alert } from "@/components/Alert";
import { QREditorContext } from "@/contexts/QREditorContext";
import getQRReferenceHandler from "@/interface/api/getQRReference";
import { FormValidationMethods } from "@/components/Form/types";
import { SwitchWithInputProps, TSwitchWithInputContext } from "./types";
import { QREditorContextProps } from "@/contexts/QREditorContext/types";
import { getSiteInfo } from "@/sites";
import { useDebounce } from "@/hooks/useDebounce";
import { useApi } from "@/hooks/useApi";
import { QR_REFERENCE_NOT_VALID, REFERENCE_EXITS } from "@/utils/qrEditorHelper";

import "./styles.scss";

export const SwitchWithInputContext = createContext<TSwitchWithInputContext>(null);

export const SwitchWithInput = ({
  label,
  checked,
  content,
  formFields,
  warning,
}: SwitchWithInputProps) => {
  const { t } = useTranslation("qr-editor-stats");

  const methods: FormValidationMethods = useFormContext();
  const { qrData, isQRfinished, setQrData } =
    useContext<QREditorContextProps<any>>(QREditorContext);

  const { qrCodeReference } = qrData;
  const { hasCustomReference } = qrData.data;

  const { domainName } = getSiteInfo(process.env.NEXT_PUBLIC_DOMAIN!) || {};
  const baseUrl = `https://${domainName}/p/`;

  const switchDefaultVal = hasCustomReference ? false : checked;

  const referenceInputValue =
    hasCustomReference && qrData.data.customReference
      ? qrData.data.customReference
      : qrCodeReference;

  const [switchVal, setSwitch] = useState(switchDefaultVal);
  const [inputVal, setInputVal] = useState(referenceInputValue || "");
  const [searchVal, setSearchVal] = useState("");

  const toogleSwitch = () => setSwitch((prevState) => !prevState);

  useEffect(() => {
    if (!switchVal) document.getElementById("customReference").focus();
    const copyQrData = { ...qrData.data };

    if (
      copyQrData.hasOwnProperty("hasCustomReference") &&
      copyQrData["hasCustomReference"] === true
    ) {
      delete copyQrData["hasCustomReference"];
      delete copyQrData["customeReference"];
    } else {
      copyQrData["hasCustomReference"] = true;
    }

    setQrData({
      ...qrData,
      data: {
        ...copyQrData,
      },
    });
  }, [switchVal]);

  const { request: requestQRReference, error } = useApi(getQRReferenceHandler.getQRReference);

  const handleQRReferenceRequest = async (e) => {
    if (e !== undefined) {
      const res = await requestQRReference({ qrReference: e });
    }
  };

  useEffect(() => {
    if (error?.data.code === QR_REFERENCE_NOT_VALID)
      methods.setError("customReference", {
        message: t("_referencePattern"),
      });

    if (error?.data.code === REFERENCE_EXITS)
      methods.setError("customReference", {
        message: t("_referenceExist"),
      });
  }, [error]);

  const debounceValue = useDebounce(searchVal, 300);

  useEffect(() => {
    searchVal !== "" && handleQRReferenceRequest(searchVal);
  }, [debounceValue]);

  const handleChange = (e) => {
    setInputVal(e);
    setSearchVal(e);
  };

  const hasError = methods.formState.errors["customReference"]?.message;

  return (
    <SwitchWithInputContext.Provider value={{ toogleSwitch, switchVal }}>
      <div className="switchWithInput">
        <Switch
          label={t(label)}
          content={t(content, { domain_name: domainName })}
        />
      </div>
      <>
        {!switchVal ? (
          <>
            <div className="Input__label-container">
              {formFields[0].label && (
                <label
                  data-testid="Input__label"
                  htmlFor={formFields[0].id}
                  className="Input__label">
                  {formFields[0].label || ""}
                </label>
              )}
            </div>
            <div className={`input-mock ${hasError ? "hasError" : ""}`}>
              <span>{baseUrl}</span>

              <Controller
                control={methods?.control}
                name="customReference"
                render={() => (
                  <Input
                    disabled={switchVal}
                    id={formFields[0].id}
                    type="text"
                    placeholder={"(Auto Create)"}
                    name={formFields[0].name}
                    onChange={handleChange}
                    className="label-input__input"
                    error={methods.formState.errors["customReference"]}
                    errorMessage={methods.formState.errors["customReference"]?.message}
                    {...(checked && { value: inputVal })}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: t("_requiredMessage"),
                  },
                  maxLength: {
                    value: 36,
                    message: t("_referenceMaxLength"),
                  },
                  /*  pattern: {
                    value: new RegExp("^[a-zA-Z0-9]+$"),
                    message: t("_referencePattern"),
                  }, */
                }}
              />
            </div>
            {hasError && (
              <p
                data-testid="Input__error-message"
                className="Input__error-message_custom body-s">
                <FiAlertCircle />
                {methods.formState.errors["customReference"]?.message as string}
              </p>
            )}
          </>
        ) : (
          <div className="input-mock single">
            {t(formFields[0].placeholder, { domain_name: domainName })}
          </div>
        )}

        {isQRfinished && warning && (
          <Alert
            icon={<IoMdWarning aria-label="warning-icon" />}
            text={t(warning)}
            type={"warning"}
            className="customReference__warning"
          />
        )}
      </>
    </SwitchWithInputContext.Provider>
  );
};
